@import "crypto-icons/styles.css";
@import "crypto-icons/font.css";

a:hoover {
	text-decoration: overline;
}

.currency-icon {
	font-family: 'coins', sans-serif !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	border-radius: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 36px !important;
	height: 36px !important;
	font-size: 20px !important;
	color: #fff !important;
}

.subcurrency-icon {
	font-family: 'coins', sans-serif !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	border-radius: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 10px !important;
	height: 10px !important;
	font-size: 20px !important;
	color: #fff !important;
}

.qrscan_window {
	position:absolute; 
	border: 2px solid; 
	top:100%;
	right:0px;
	width:204px;
	z-index: 1000;
}

.qrscan_window_error {
	position:absolute; 
	border: solid 1px #aaa9a9;
	border-radius: 3px;
	transform: translate3d(0, 0, 0);
	cursor: default;
	bottom:80%;
	right:20px;
	width: 200px;
	height: 50px;
	display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  color: #4d4d4d;
} 


.number_input {
	font: inherit;
	font-size: 14px;
	letter-spacing: inherit;
	color: currentColor;
	padding: 4px 0 5px;
	border: 0;
	box-sizing: content-box;
	background: none;
	height: 1.4375em;
	margin: 0;
	-webkit-tap-highlight-color: transparent;
	display: block;
	min-width: 0;
	width: 100%;
	-webkit-animation-name: mui-auto-fill-cancel;
	animation-name: mui-auto-fill-cancel;
	-webkit-animation-duration: 10ms;
	animation-duration: 10ms;
	width: 100% !important;
	height: 1.375rem;
	padding: 0 !important;
	padding: 16.5px 14px;
	height: 1.375rem;
	width: -webkit-max-content !important;
	width: -moz-max-content !important;
	width: max-content !important;
}

.number_input:focus {
	outline: 0;
}


.send_amount_1_input{
   
	font: inherit;
	font-size: 14px;
	letter-spacing: inherit;
    color: currentColor;
	box-sizing: content-box;
	background: none;
    border: none;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 1.375rem;
    padding:0px;
	width: 100%;
	font-weight: 700

}

.send_amount_2_input{
   
	font: inherit;
	font-size: 14px;
	letter-spacing: inherit;
    color: currentColor;
	box-sizing: content-box;
	background: none;
    border: none;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 1.375rem;
    padding:0px;
	width: 100%;

}

.send_token_input{
   
	font: inherit;
	font-size: 14px;
	letter-spacing: inherit;
    color: currentColor;
	box-sizing: content-box;
	background: none;
    border: none;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 1.375rem;
    padding:0px;
	width: 100%;

}






.send_amount_1_input:focus, .send_amount_1_input:hover, .send_amount_2_input:focus, .send_amount_2_input:hover, .send_token_input:focus, .send_token_input:hover {
  
   outline: 0
}